
import { Component, Vue } from 'vue-property-decorator';
import { Header } from '@/components/header/';
import { Sidebar } from '@/components/sidebar';
import { global_store, auth_store } from '@/store';
import { initializeSocket } from '@/utils/sockets/socket';
import { Layouts } from './types';

@Component({
  name: Layouts.DEFAULT,
  components: {
    Header,
    Sidebar,
  },
})
export default class DefaultLayout extends Vue {
  get sidebar_width() {
    if (!this.$route.meta?.hide_sidebar) {
      const { is_mini_sidebar, sidebar_mini_width, sidebar_width } = global_store.state;

      return is_mini_sidebar ? sidebar_mini_width : sidebar_width;
    }

    return false;
  }

  get main_padding() {
    return { paddingLeft: this.sidebar_width, paddingTop: 0 };
  }

  get user_id() {
    return auth_store.state.user.id;
  }

  time_interval: NodeJS.Timer | null = null;

  created() {
    this.time_interval = setInterval(() => {
      const time = this.getTime();

      global_store.actions.set_time(time);
    }, 1000);

    if (this.user_id) {
      initializeSocket();
    }
  }

  beforeDestroy() {
    if (this.time_interval) {
      clearInterval(this.time_interval);
    }
  }

  getTime() {
    const today = new Date();
    const hours = today.getHours() >= 10 ? today.getHours() : `0${today.getHours()}`;
    const minutes = today.getMinutes() >= 10 ? today.getMinutes() : `0${today.getMinutes()}`;

    return `${hours}:${minutes}`;
  }
}
